import {
	Link,
	createRoute,
	useNavigate,
	useSearch,
} from "@tanstack/react-router";
import {
	createColumnHelper,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { useMemo } from "react";
import { z } from "zod";
import { usePayments } from "../../../api/usePayments";
import { NoData } from "../../../components/charts/no-data";
import { Loading } from "../../../components/loading";
import {
	CurrencyFilter,
	StatusFilter,
} from "../../../components/payment/payment-filters";
import { StatusPill } from "../../../components/payment/status-pill";
import { Button } from "../../../components/ui/button";
import { Table } from "../../../components/ui/table";
import { cn } from "../../../lib/cn";
import { numberFormatter } from "../../../lib/number";
import {
	type Payment,
	type PaymentStatus,
	type PaymentStatusWithExpired,
	paymentStatusWithExpiredSchema,
} from "../../../schemas/payment";
import { dashLayout } from "../_layout";

const paymentsSearchParamsSchema = z.object({
	status: paymentStatusWithExpiredSchema.array().optional(),
	currency: z.string().optional(),
	page: z.number().catch(1),
});

const statusMap: Record<string, PaymentStatus[]> = {
	successful: ["SETTLED"],
	failed: ["FAILED"],
	canceled: ["CANCELED"],
};

const tabsWithActiveCondition = [
	{
		label: "successful",
		condition: (status: PaymentStatusWithExpired[]) =>
			status.length === 1 && status.includes("SETTLED"),
	},
	{
		label: "failed",
		condition: (status: PaymentStatusWithExpired[]) =>
			status.length === 1 && status.includes("FAILED"),
	},
	{
		label: "canceled",
		condition: (status: PaymentStatusWithExpired[]) =>
			status.length === 1 && status.includes("CANCELED"),
	},
];

export const dashPaymentsRoute = createRoute({
	getParentRoute: () => dashLayout,
	path: "/payments",
	component: PaymentsPage,
	validateSearch: paymentsSearchParamsSchema,
});

function PaymentsPage() {
	const { status, currency, page } = useSearch({ from: "/dash/payments" });
	const navigate = useNavigate({ from: "/payments" });
	const { payments, pagination, isLoading } = usePayments({
		status,
		currency,
		page,
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: refresh on status and page change
	const data = useMemo(() => {
		if (!payments) {
			return [];
		}

		return payments;
	}, [currency, status, page]);

	const columnHelper = createColumnHelper<Payment>();

	const columns = [
		// columnHelper.display({
		//   id: "select",
		//   header: () => <input type="checkbox" />,
		//   cell: (props) => <input type="checkbox" />,
		// }),
		columnHelper.accessor("amount", {
			header: "Amount",
			cell: ({ getValue, row }) => (
				<Link
					to="/payment/$id"
					params={{
						id: row.original.id,
					}}
					className="block w-full h-full"
				>
					{numberFormatter(+getValue() / 100, {
						style: "currency",
						currency: row.original.currency,
					})}
				</Link>
			),
		}),
		columnHelper.accessor("status", {
			header: "Status",
			cell: ({ getValue, row }) => (
				<Link
					to="/payment/$id"
					params={{
						id: row.original.id,
					}}
					className="block w-full h-full"
				>
					<StatusPill status={getValue()} />
				</Link>
			),
		}),
		columnHelper.accessor("createdAt", {
			header: "Date",
			cell: ({ getValue, row }) => (
				<Link
					to="/payment/$id"
					params={{
						id: row.original.id,
					}}
					className="block w-full h-full"
				>
					{dayjs(getValue()).format("MMM DD, H:mm a")}
				</Link>
			),
		}),
		columnHelper.display({
			header: "Type",
			cell: ({ row }) => (
				<Link
					to="/payment/$id"
					params={{
						id: row.original.id,
					}}
					className="block w-full h-full"
				>
					One-time
				</Link>
			),
		}),
		columnHelper.accessor("bank", {
			header: "Bank",
			cell: ({ getValue, row }) => {
				const bank = getValue();

				if (!bank) {
					return "–";
				}

				return (
					<Link
						to="/payment/$id"
						params={{
							id: row.original.id,
						}}
						className="block w-full h-full"
					>
						<div className="w-fit flex items-center justify-between space-x-2">
							<img src={bank.logo} alt={bank.name} className="w-5 h-5" />
							<span>{bank.name}</span>
						</div>
					</Link>
				);
			},
		}),
	];

	const table = useReactTable({
		columns,
		data: (payments.length || 0) > data.length ? payments : data,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div className="py-8 px-12 w-full flex flex-col space-y-4">
			<h1 className="font-semibold text-2xl">Payments</h1>
			<div className="flex space-x-4 justify-between">
				<Link
					from={dashPaymentsRoute.to}
					to={dashPaymentsRoute.to}
					search={({ currency }) => ({
						currency,
						page: 1,
					})}
					className={cn(
						"flex-1 px-6 py-4 border hover:border-neutral-400 capitalize rounded-lg transition-colors duration-200",
						!status || status.length === 0
							? "border-neutral-400 bg-neutral-50"
							: "border-neutral-300 text-neutral-500",
					)}
				>
					All
				</Link>
				{tabsWithActiveCondition.map(({ label, condition }) => (
					<Link
						key={label}
						from={dashPaymentsRoute.to}
						to={dashPaymentsRoute.to}
						search={({ currency }) => ({
							currency,
							page: 1,
							status: statusMap[label],
						})}
						className={cn(
							"flex-1 px-6 py-4 border hover:border-neutral-400 capitalize rounded-lg transition-colors duration-200",
							status && condition(status)
								? "border-neutral-400 bg-neutral-50"
								: "border-neutral-300 text-neutral-500",
						)}
					>
						{label}
					</Link>
				))}
			</div>
			<div className="flex space-x-2 items-center">
				<CurrencyFilter />
				<StatusFilter />
				<Link
					from={dashPaymentsRoute.to}
					to={dashPaymentsRoute.to}
					search={{
						page: 1,
					}}
					className="text-xs font-semibold underline text-neutral-500"
				>
					Clear
				</Link>
			</div>
			<Table table={table}>
				<table className="w-full table-fixed">
					<Table.Header />
					{!isLoading && <Table.Body />}
				</table>
				{isLoading && <Loading className="w-full py-36" />}
				{!isLoading && table.getRowModel().rows.length === 0 && (
					<NoData className="m-4" />
				)}
			</Table>
			<div className="w-full py-2 flex justify-center items-center space-x-4">
				<Button
					intent="outline"
					className="border rounded p-1"
					onClick={() =>
						navigate({
							from: dashPaymentsRoute.to,
							to: dashPaymentsRoute.to,
							search: (current) => ({
								...current,
								page: 1,
							}),
						})
					}
					disabled={page <= 1}
				>
					{"<<"}
				</Button>
				<Button
					intent="outline"
					className="border rounded p-1"
					onClick={() =>
						navigate({
							from: dashPaymentsRoute.to,
							to: dashPaymentsRoute.to,
							search: (current) => ({
								...current,
								page: pagination.currentPage - 1,
							}),
						})
					}
					disabled={page <= 1}
				>
					{"<"}
				</Button>
				<Button
					intent="outline"
					className="border rounded p-1"
					onClick={() =>
						navigate({
							from: dashPaymentsRoute.to,
							to: dashPaymentsRoute.to,
							search: (current) => ({
								...current,
								page: pagination.currentPage + 1,
							}),
						})
					}
					disabled={page >= pagination.lastPage}
				>
					{">"}
				</Button>
				<Button
					intent="outline"
					className="border rounded p-1"
					onClick={() =>
						navigate({
							from: dashPaymentsRoute.to,
							to: dashPaymentsRoute.to,
							search: (current) => ({
								...current,
								page: pagination.lastPage,
							}),
						})
					}
					disabled={page >= pagination.lastPage}
				>
					{">>"}
				</Button>
				<span className="text-sm text-neutral-800">
					Page {pagination.currentPage ?? 0} out of {pagination.lastPage ?? 0}
				</span>
				<span className="text-sm text-neutral-800">
					{pagination.total ?? 0} total results
				</span>
			</div>
		</div>
	);
}
