import { createRouter } from "@tanstack/react-router";
import { rootRoute } from "./pages/__root";
import { dashLayout } from "./pages/dash/_layout";
import { dashBalanceRoute } from "./pages/dash/balance";
import { dashDevsRoute } from "./pages/dash/devs";
import { dashIndexRoute } from "./pages/dash/index";
import { dashPaymentsRoute } from "./pages/dash/payments/index";
import { dashPaymentRoute } from "./pages/dash/payments/payment";
import { dashPayoutsRoute } from "./pages/dash/payouts";
import { dashPayoutRoute } from "./pages/dash/payouts/payout";
import { dashSettingsRoute } from "./pages/dash/settings";
import { loginRoute } from "./pages/login";
import { merchantRoute } from "./pages/merchant";

const routeTree = rootRoute.addChildren([
	loginRoute,
	merchantRoute,
	dashLayout.addChildren([
		dashIndexRoute,
		dashSettingsRoute,
		dashBalanceRoute,
		dashPaymentsRoute,
		dashPaymentRoute,
		dashPayoutsRoute,
		dashPayoutRoute,
		dashDevsRoute,
	]),
]);

export const router = createRouter({ routeTree, defaultPreload: "intent" });

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}
