import { zodResolver } from "@hookform/resolvers/zod";
import { usePrivy } from "@privy-io/react-auth";
import { Link, createRoute } from "@tanstack/react-router";
import Big from "big.js";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useQuote } from "../../api/use-quote";
import { useWithdraw } from "../../api/use-withdraw";
import { useBalance, useSuspenseBalance } from "../../api/useBalance";
import { useBankAccount } from "../../api/useBankAccount";
import { BankIcon } from "../../assets/bank";
import { XMarkIcon } from "../../assets/xmark";
import { Button } from "../../components/ui/button";
import { cn } from "../../lib/cn";
import { ModalContext } from "../../providers/modal";
import type { BankAccount } from "../../schemas/bank-account";
import type { Withdraw } from "../../schemas/withdraw";
import { estimateRenderedTextSize } from "../../utils/estimate-rendered-text-size";
import { dashLayout } from "./_layout";

export const dashBalanceRoute = createRoute({
	getParentRoute: () => dashLayout,
	path: "/balance",
	component: BalancePage,
});

function BalancePage() {
	const { isError } = useBankAccount({
		retry: false,
	});

	return (
		<div className="py-8 px-12 w-full flex flex-col space-y-4">
			<h1 className="font-semibold text-2xl">Balances</h1>
			{isError && (
				<div className="w-full px-4 py-2 flex items-center justify-between bg-red-100 rounded-md">
					<div className="flex items-center space-x-2">
						<BankIcon className="w-4" />
						<span className="text-sm">
							We don't have your bank details yet.
						</span>
					</div>
					<Link to="/settings" className="flex-1 text-right text-sm underline">
						Setup now
					</Link>
				</div>
			)}
			<div className="flex flex-col">
				<div className="flex space-x-2 border-b border-neutral-300">
					<Link
						to="/balance"
						activeProps={{
							className: "border-b-2 border-inflow-800",
						}}
						className="pb-2"
						activeOptions={{
							includeSearch: false,
						}}
					>
						{({ isActive }) => (
							<span
								className={cn(
									"px-2 py-1 text-sm text-neutral-700 font-medium rounded-md",
									isActive
										? "text-inflow-800 hover:bg-inflow-300/20"
										: "text-neutral-700 hover:bg-neutral-100",
								)}
							>
								Overview
							</span>
						)}
					</Link>
					<Link
						to="/payouts"
						search={{
							page: 1,
						}}
						activeProps={{
							className: "border-b-2 border-inflow-800",
						}}
						className="pb-2"
						activeOptions={{
							includeSearch: false,
						}}
					>
						{({ isActive }) => (
							<span
								className={cn(
									"px-2 py-1 text-sm font-medium rounded-md",
									isActive
										? "text-inflow-800 hover:bg-inflow-300/20"
										: "text-neutral-700 hover:bg-neutral-100",
								)}
							>
								Payouts
							</span>
						)}
					</Link>
				</div>
				<div className="flex flex-col _border-b _border-neutral-300 divide-y divide-neutral-300">
					<BalanceOverview />
					{/* <IncomingPayouts /> */}
				</div>
			</div>
		</div>
	);
}

function BalanceOverview() {
	const { balance } = useBalance();
	const { bankAccount, isError } = useBankAccount();
	const ctx = useContext(ModalContext);

	return (
		<section className="py-6 flex flex-col">
			<div className="flex justify-between items-center">
				<div className="flex flex-col space-y-1">
					<h2 className="font-semibold text-lg">USDC Balance</h2>
					{balance ? (
						<span className="flex space-x-1 text-sm text-neutral-700">
							<span>{balance.currencySymbol}</span>
							<span>{balance.readableAmount}</span>
						</span>
					) : (
						<div className="w-20 h-5 bg-neutral-300 rounded-md animate-pulse" />
					)}
				</div>
				<Button
					intent="outline"
					disabled={
						!bankAccount || isError || (balance?.readableAmount ?? 0) <= 1
					}
					onClick={async () => {
						if (bankAccount) {
							ctx?.open(<ConfigureAmountModal bankAccount={bankAccount} />);
						}
					}}
				>
					Withdraw
				</Button>
			</div>
		</section>
	);
}

// function IncomingPayouts() {
//   return (
//     <section className="py-6 flex flex-col">
//       <div className="flex flex-col space-y-1">
//         <h2 className="font-semibold text-lg">On the way to your bank</h2>
//         <span className="flex space-x-1 text-sm text-neutral-700">
//           <span>USDC</span>
//           <span>0.00</span>
//         </span>
//       </div>
//     </section>
//   );
// }

const formValuesSchema = z.object({
	amount: z.string(),
});

type FormValues = z.infer<typeof formValuesSchema>;

type ConfigureAmountModalProps = {
	bankAccount: BankAccount;
};

function ConfigureAmountModal({ bankAccount }: ConfigureAmountModalProps) {
	const ctx = useContext(ModalContext);
	const { balance } = useSuspenseBalance();
	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
		setError,
		watch,
		clearErrors,
	} = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
		defaultValues: {
			amount: "0",
		},
	});

	useEffect(() => {
		if (balance) {
			reset({
				amount: balance.readableAmount.toFixed(2),
			});
		}
	}, [balance, reset]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: Refresh every time amount changes
	useEffect(() => {
		const amount = watch("amount");

		if (amount) {
			const num = Number.parseFloat(amount.replace(/,/g, "."));

			if (num > balance?.readableAmount) {
				setError("amount", {
					type: "manual",
					message: "Amount exceeds your balance",
				});
			} else {
				clearErrors("amount");
			}
		} else {
			clearErrors("amount");
		}
	}, [watch, watch("amount"), balance, clearErrors, setError]);

	const renderedAmount = watch("amount")?.length > 0 ? watch("amount") : "0";

	function onSubmit(values: FormValues, e?: React.BaseSyntheticEvent) {
		e?.preventDefault();

		ctx?.open(
			<ConfirmWithdrawModal
				bankAccount={bankAccount}
				amount={Number.parseFloat(values.amount)}
			/>,
		);
	}

	return (
		<form
			className="w-full max-w-lg flex flex-col space-y-8 bg-white rounded-md"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="pt-4 px-6 flex justify-between items-center">
				<span className="font-medium">Choose your withdraw amount</span>
				<button type="button" onClick={() => ctx?.close()}>
					<XMarkIcon className="w-4 h-4" />
				</button>
			</div>
			<div className="px-6 flex flex-col">
				<div className="flex items-center justify-center">
					<label
						htmlFor="amount"
						className={cn(
							"pl-3 text-xl font-bold",
							errors.amount && "text-red-700",
						)}
					>
						USDC
					</label>
					<input
						id="amount"
						type="text"
						placeholder="0"
						style={
							{
								"--input-width": `${
									estimateRenderedTextSize(renderedAmount, "20px DM Sans") + 24
								}px`, // Caps length at 10ch
							} as React.CSSProperties
						}
						className={cn(
							"px-3 py-1.5 text-xl font-bold outline-none w-[var(--input-width)]",
							errors.amount && "text-red-700",
						)}
						{...register("amount")}
						value={watch("amount")}
						onChange={(e) => {
							const value = e.target.value;

							// check for invalid characters
							if (!/^\d*[\.,]?\d*$/.test(value)) {
								return;
							}

							// check for a dot and a comma
							if (value.indexOf(".") > -1 && value.indexOf(",") > -1) {
								return;
							}

							// check for multiple dots or commas
							if (value.split(".").length > 2 || value.split(",").length > 2) {
								return;
							}

							register("amount").onChange(e);
						}}
					/>
				</div>
				<div className="mx-auto px-3 py-2 bg-neutral-100 rounded-full flex items-center justify-center">
					<span className="text-xs font-medium text-neutral-600">
						Available balance: {balance?.currencySymbol}{" "}
						{balance?.readableAmount.toFixed(2)}
					</span>
				</div>
			</div>
			<div className="pb-4 px-6 flex space-x-4 justify-end items-center">
				<Button onClick={() => ctx?.close()}>Cancel</Button>
				<Button
					disabled={!!errors.amount}
					type="submit"
					color="inflow"
					intent="solid"
				>
					Confirm
				</Button>
			</div>
		</form>
	);
}

type ConfirmWithdrawModalProps = {
	bankAccount: BankAccount;
	amount: number;
};

function ConfirmWithdrawModal({
	bankAccount,
	amount,
}: ConfirmWithdrawModalProps) {
	const ctx = useContext(ModalContext);
	const { authenticated, ready, login } = usePrivy();
	const { quote } = useQuote();
	const { mutate, isPending, isError } = useWithdraw({
		onSuccess: (data) => {
			ctx?.open(
				<WithdrawalConfirmationModal
					bankAccount={bankAccount}
					withdraw={data}
				/>,
			);
		},
	});

	return (
		<div className="w-full max-w-lg flex flex-col space-y-4 bg-white rounded-md">
			<div className="pt-4 px-6 flex justify-between items-center">
				<span className="font-medium">Confirm withdrawal</span>
				<button type="button" onClick={() => ctx?.close()}>
					<XMarkIcon className="w-4 h-4" />
				</button>
			</div>
			{isError && (
				<div className="px-6 py-4 flex items-center space-x-4 bg-red-100 border-y border-red-200">
					<span className="text-sm text-red-700">
						An error occurred while withdrawing your funds. If the problem
						persists, please contact support.
					</span>
				</div>
			)}
			{quote ? (
				<span className="px-6 text-sm text-neutral-700">
					You are about to withdraw {amount} USDC (
					<span className="font-medium">
						~€ {(quote?.buy_rate * amount).toFixed(2)}
					</span>
					) to the account below.
				</span>
			) : null}
			<div className="px-6 py-6 flex items-center space-x-4 bg-neutral-100 border-y border-neutral-200">
				<div className="p-2 bg-neutral-200 rounded-md">
					<BankIcon className="w-5 h-5 fill-neutral-700" />
				</div>
				<div className="flex flex-col">
					<code className="text-sm">{bankAccount.accountOwnerName}</code>
					<code className="text-sm">
						••••{bankAccount.iban} / {bankAccount.bic}
					</code>
				</div>
			</div>
			<div className="pb-4 px-6 flex space-x-4 justify-end items-center">
				<Button onClick={() => ctx?.close()}>Cancel</Button>
				<Button
					color="inflow"
					intent="solid"
					disabled={!ready || isError}
					onClick={async () => {
						if (!authenticated) {
							login();
						} else {
							mutate(new Big(amount).mul(100).toNumber());
						}
					}}
				>
					{isPending
						? "Loading..."
						: ready && authenticated
							? "Confirm"
							: "Verify with code"}
				</Button>
			</div>
		</div>
	);
}

type WithdrawalConfirmationModalProps = {
	bankAccount: BankAccount;
	withdraw: Withdraw;
};

function WithdrawalConfirmationModal({
	bankAccount,
}: WithdrawalConfirmationModalProps) {
	const ctx = useContext(ModalContext);
	// const navigate = useNavigate({
	//   from: "/balance",
	// });

	return (
		<div className="w-full max-w-lg flex flex-col space-y-4 bg-white rounded-md">
			<div className="pt-4 px-6 flex justify-between items-center">
				<span className="font-medium">Withdrawal confirmation</span>
				<button type="button" onClick={() => ctx?.close()}>
					<XMarkIcon className="w-4 h-4" />
				</button>
			</div>
			<p className="px-6 text-sm text-neutral-700">
				Your withdrawal request has been successfully requested. Your funds will
				be transferred to the account below within 1-2 business days.
			</p>
			<div className="px-6 py-6 flex items-center space-x-4 bg-neutral-100 border-y border-neutral-200">
				<div className="p-2 bg-neutral-200 rounded-md">
					<BankIcon className="w-5 h-5 fill-neutral-700" />
				</div>
				<div className="flex flex-col">
					<code className="text-sm">{bankAccount.accountOwnerName}</code>
					<code className="text-sm">
						••••{bankAccount.iban} / {bankAccount.bic}
					</code>
				</div>
			</div>
			<div className="pb-4 px-6 flex space-x-4 justify-end items-center">
				<Button onClick={() => ctx?.close()}>Close</Button>
				{/* <Button
          color="inflow"
          intent="solid"
          onClick={() => {
            // navigate({
            //   // TODO: redirect to withdraw page
            //   to: "/",
            // });
          }}
        >
          See details
        </Button> */}
			</div>
		</div>
	);
}
