import { getAccessToken } from "@privy-io/react-auth";
import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";

async function getQuote() {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	return fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/quote`,
		z.object({
			midmarket_rate: z.coerce.number(),
			buy_rate: z.coerce.number(),
			sell_rate: z.coerce.number(),
		}),
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);
}

export function quoteOptions() {
	return queryOptions({
		queryKey: ["quote"],
		queryFn: () => getQuote(),
		staleTime: 10 * 1000,
	});
}

export function useQuote() {
	const { data, ...query } = useQuery(quoteOptions());

	return {
		quote: data,
		...query,
	};
}

export function useSuspenseQuote() {
	const { data, ...query } = useSuspenseQuery(quoteOptions());

	return {
		quote: data,
		...query,
	};
}
