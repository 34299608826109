import { z } from "zod";

export const payoutStatusSchema = z.enum([
	"INITIATED",
	"SENT",
	"PROCESSING",
	"SUBMITTED",
	"COMPLETED",
	"CANCELED",
	"FAILED",
]);

export type PayoutStatus = z.infer<typeof payoutStatusSchema>;

export const payoutSchema = z.object({
	id: z.string(),
	amount: z.number(),
	amountInWei: z.string(),
	currency: z.enum(["USDC"]),
	status: payoutStatusSchema,
	createdAt: z.coerce.date(),
	updatedAt: z.coerce.date(),
	payment: z.object({
		sourceAmount: z.coerce.number(),
		sourceCurrency: z.enum(["usdc"]),
		sourceSubtotalAmount: z.coerce.number(),
		finalAmount: z.coerce.number(),
		finalCurrency: z.enum(["eur"]),
		exchangeRate: z.coerce.number(),
		rail: z.enum(["sepa"]),
	}),
	userId: z.string(),
});

export type Payout = z.infer<typeof payoutSchema>;
