import { cn } from "../../lib/cn";
import type { PaymentStatus } from "../../schemas/payment";
import type { PayoutStatus } from "../../schemas/payout";

type Status = PaymentStatus | PayoutStatus;

const colors: Record<Status, [textColor: string, bgColor: string]> = {
	// Payment statuses
	INCOMPLETE: ["text-amber-700", "bg-amber-500/40"],
	AUTHORIZED: ["text-blue-700", "bg-blue-500/40"],
	SETTLED: ["text-green-700", "bg-green-500/40"],
	EXPIRED: ["text-red-700", "bg-red-500/40"],
	FAILED: ["text-red-700", "bg-red-500/40"],
	REFUNDED: ["text-red-700", "bg-red-500/40"],
	CANCELED: ["text-red-700", "bg-red-500/40"],

	// Payout statuses
	INITIATED: ["text-amber-700", "bg-amber-500/40"],
	SENT: ["text-blue-700", "bg-blue-500/40"],
	PROCESSING: ["text-blue-700", "bg-blue-500/40"],
	SUBMITTED: ["text-blue-700", "bg-blue-500/40"],
	COMPLETED: ["text-green-700", "bg-green-500/40"],
} as const;

const labels: Record<Status, string> = {
	// Payment statuses
	INCOMPLETE: "Incomplete",
	AUTHORIZED: "Authorized",
	SETTLED: "Settled",
	EXPIRED: "Expired",
	FAILED: "Failed",
	REFUNDED: "Refunded",
	CANCELED: "Canceled",

	// Payout statuses
	INITIATED: "Initiated",
	SENT: "Sent",
	PROCESSING: "Processing",
	SUBMITTED: "Submitted",
	COMPLETED: "Completed",
} as const;

type Props = {
	status: Status;
};

export function StatusPill({ status }: Props) {
	return (
		<span className={cn("rounded-md py-0.5 px-1.5 text-sm", colors[status])}>
			{labels[status]}
		</span>
	);
}
