import { type VariantProps, cva } from "cva";
import { forwardRef } from "react";

// Docs: https://cva.style/docs/getting-started/variants
const button = cva(
	"whitespace-nowrap rounded-md relative hover:cursor-pointer disabled:cursor-not-allowed transition-colors ease-in-out duration-200 flex flex-row items-center justify-center space-x-2 font-medium",
	{
		variants: {
			intent: {
				outline: "border bg-transparent disabled:opacity-50",
				solid: "border disabled:opacity-50",
			},
			color: { black: "", red: "", inflow: "" },
			size: { small: "text-sm px-3 py-1" },
		},
		compoundVariants: [
			{
				intent: "outline",
				color: "black",
				class:
					"border-neutral-300 text-neutral-700 hover:border-neutral-400 hover:text-neutral-800 shadow-sm",
			},
			{
				intent: "outline",
				color: "red",
				class:
					"border-red-500 text-red-500 hover:border-red-600 hover:text-red-600 shadow-sm",
			},
			{
				intent: "solid",
				color: "inflow",
				class:
					"bg-inflow-300/80 border-inflow-300/80 text-black hover:bg-inflow-300 hover:border-inflow-300 shadow-sm",
			},
		],
		defaultVariants: {
			intent: "outline",
			color: "black",
			size: "small",
		},
	},
);

export type ButtonProps = {
	icon?: React.ReactNode;
	iconPosition?: "left" | "right";
	isLoading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
	VariantProps<typeof button>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			intent,
			color,
			size,
			icon,
			iconPosition = "right",
			isLoading,
			className,
			children,
			...buttonProps
		}: ButtonProps,
		ref,
	) => {
		return (
			<button
				type="button"
				{...buttonProps}
				ref={ref}
				className={button({ intent, color, size, className })}
			>
				{iconPosition === "left" && !isLoading && icon}
				{!!children && (
					<div className="flex flex-row items-center space-x-2">
						{isLoading ? <span>Chargement...</span> : <>{children}</>}
					</div>
				)}
				{iconPosition === "right" && !isLoading && icon}
			</button>
		);
	},
);

Button.displayName = "Button";
