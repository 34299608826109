import { getAccessToken } from "@privy-io/react-auth";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { fetcher } from "../lib/fetcher";
import { bankAccountSchema } from "../schemas/bank-account";
import { payoutSchema } from "../schemas/payout";

// const statusLabels: Record<PayoutStatus, string> = {
//   INITIATED: "Payout initiated",
//   SENT: "Payout sent",
//   PROCESSING: "Payout processing",
//   SUBMITTED: "Payout submitted",
//   COMPLETED: "Payout completed",
//   CANCELED: "Payout canceled",
//   FAILED: "Payout failed",
// };

async function getPayout(id: string) {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	return fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/withdraw/${id}`,
		payoutSchema.extend({
			bank: bankAccountSchema.nullable(),
		}),
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);

	// const initiatedAt = payout.timeline.find((t) => t.status === "INCOMPLETE");
	// const settledAt = payout.timeline.find((t) => t.status === "SETTLED");

	// return {
	//   ...payout,
	//   initiatedAt: initiatedAt ? new Date(initiatedAt.timestamp) : null,
	//   settledAt: settledAt ? new Date(settledAt.timestamp) : null,
	//   timeline: payout.timeline
	//     .map((t) => ({
	//       ...t,
	//       label: statusLabels[t.status],
	//     }))
	//     .reverse(),
	// };
}

export function payoutOptions(id: string) {
	return queryOptions({
		queryKey: ["payout", id],
		queryFn: () => getPayout(id),
		staleTime: 5 * 1000,
	});
}

export function usePayout(id: string) {
	const { data, ...query } = useQuery(payoutOptions(id));

	return {
		payout: data,
		...query,
	};
}
