import { useSuspenseQuery } from "@tanstack/react-query";
import { Link, createRoute, useParams } from "@tanstack/react-router";
import dayjs from "dayjs";
import { payoutOptions } from "../../../api/usePayout";
import { NoData } from "../../../components/charts/no-data";
import { StatusPill } from "../../../components/payment/status-pill";
import { numberFormatter } from "../../../lib/number";
import { stablecoinToFiatMapping } from "../../../utils/stablecoin-to-fiat";
import { queryClient } from "../../__root";
import { dashLayout } from "../_layout";

export const dashPayoutRoute = createRoute({
	getParentRoute: () => dashLayout,
	path: "/payout/$id",
	component: PayoutPage,
	loader: async ({ params }) => {
		await queryClient.ensureQueryData(payoutOptions(params.id));
	},
});

function PayoutPage() {
	const { id } = useParams({ from: "/dash/payout/$id" });
	const { data: payout } = useSuspenseQuery(payoutOptions(id));

	if (!payout) {
		return null;
	}

	return (
		<div className="py-8 px-12 w-full flex flex-col space-y-12">
			<div className="flex flex-col space-y-1">
				<Link
					to="/payouts"
					search={{ page: 1 }}
					className="text-sm font-semibold text-inflow-800 hover:underline"
				>
					Payouts
				</Link>
				<div className="flex space-x-2 items-center">
					<h1 className="font-semibold text-2xl">
						{numberFormatter(payout.amount / 100, {
							style: "currency",
							currency: stablecoinToFiatMapping[payout.currency],
						})}{" "}
						{payout.currency}
					</h1>
					<StatusPill status={payout.status} />
				</div>
			</div>
			<div className="flex flex-col lg:flex-row space-x-0 space-y-12 lg:space-x-12 lg:space-y-0">
				<div className="flex-1 flex flex-col space-y-12">
					<section className="flex flex-col space-y-4">
						<div className="flex space-x-2 items-center border-b border-neutral-300 pb-2">
							<h2 className="font-semibold text-xl">Summary</h2>
						</div>
						<div className="flex flex-col space-y-2">
							<div className="flex items-center justify-between">
								<span className="w-40 text-neutral-500">Requested amount</span>
								<span>
									{numberFormatter(payout.payment.sourceAmount, {
										style: "currency",
										currency: stablecoinToFiatMapping[payout.currency],
									})}
								</span>
							</div>
							<div className="flex items-center justify-between">
								<span className="w-40 text-neutral-500">Subtotal</span>
								<span>
									{numberFormatter(payout.payment.sourceSubtotalAmount, {
										style: "currency",
										currency: stablecoinToFiatMapping[payout.currency],
									})}
								</span>
							</div>
							<div className="flex items-center justify-between">
								<span className="w-40 text-neutral-500">Rate</span>
								{payout.status === "COMPLETED" ? (
									<div className="flex space-x-2 uppercase">
										<span>1 {payout.currency}</span>
										<span>{">"}</span>
										<span>
											{payout.payment.exchangeRate}{" "}
											{payout.payment.finalCurrency}
										</span>
									</div>
								) : (
									<span>–</span>
								)}
							</div>
							<div className="flex items-center justify-between">
								<span className="w-40 text-neutral-500">Net</span>
								{payout.status === "COMPLETED" ? (
									<span className="uppercase">
										{numberFormatter(payout.payment.finalAmount, {
											style: "currency",
											currency: payout.payment.finalCurrency,
										})}
									</span>
								) : (
									<span>–</span>
								)}
							</div>
						</div>
					</section>
					<section className="flex flex-col space-y-4">
						<div className="flex space-x-2 items-center border-b border-neutral-300 pb-2">
							<h2 className="font-semibold text-xl">Account details</h2>
						</div>
						{payout.bank ? (
							<div className="flex flex-col space-y-2">
								<div className="flex items-center justify-between">
									<span className="w-40 text-neutral-500">Type</span>
									<span>Bank account</span>
								</div>
								<div className="flex items-center justify-between">
									<span className="w-40 text-neutral-500">IBAN</span>
									<span className="font-mono text-sm">
										••••{payout.bank.iban}
									</span>
								</div>
								<div className="flex items-center justify-between">
									<span className="w-40 text-neutral-500">BIC</span>
									<span className="font-mono text-sm">{payout.bank.bic}</span>
								</div>
								<div className="flex items-center justify-between">
									<span className="w-40 text-neutral-500">Created</span>
									<span>
										{dayjs(payout.bank.createdAt).format("MMM DD, H:mm a")}
									</span>
								</div>
							</div>
						) : (
							<NoData className="my-4 h-52" />
						)}
					</section>
				</div>
				<section className="min-w-60 flex flex-col space-y-4">
					<div className="flex space-x-2 items-center border-b border-neutral-300 pb-2">
						<h2 className="font-semibold text-xl">Details</h2>
					</div>
					<div className="flex flex-col space-y-4">
						<div className="flex flex-col space-y-1">
							<span className="text-neutral-700 text-sm font-semibold">
								Initiated at
							</span>
							<span className="text-neutral-500 text-sm">
								{dayjs(payout.createdAt).format("MMM DD, H:mm a")}
							</span>
						</div>
						<div className="flex flex-col space-y-1">
							<span className="text-neutral-700 text-sm font-semibold">
								Payment rail
							</span>
							<span className="text-neutral-500 text-sm">
								{payout.payment.rail.toUpperCase()}
							</span>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}
